import React, { useState, useEffect, useRef } from "react";
import styled, { withTheme } from "styled-components";
import HeroBG from "../assets/images/founders-circle/header-bg.png";

function FoundersCircle({ theme }) {
  const [activeAccordion, setActiveAccordion] = useState(1);

  const winnerList = [
    {
      name: "Eli Hiller",
      dept: "Engineering",
      award: "Founders Award",
      img: "https://assets.mx.com/hub/images/founders-circle/2024/founder-eli.png",
      description: (
        <>
          <p>
            Andrew sits in a really interesting spot in the company between
            Sales, Customer Success, Finance and Product where he has to enforce
            rules and proper structure into each MX deal to ensure
            profitability, scalability and overall deal success. He does so with
            grace, tact and respect every single time. He is a true professional
            and comes with the proper information in hand.
          </p>
          <p>
            He doesn't engage in arguments, he just presents to facts and the
            data and ensures that the right people are always there to weigh in
            and get the job done so we can move forward. Massive respect for
            Andrew and what he brings to MX in this area.
          </p>
        </>
      ),
    },
    {
      name: "Andrew Williams",
      dept: "Finance",
      award: "Objectivity in Discourse",
      img: "https://assets.mx.com/hub/images/founders-circle/2024/founder-andrew.png",
      description: (
        <>
          <p>
            Andrew sits in a really interesting spot in the company between
            Sales, Customer Success, Finance and Product where he has to enforce
            rules and proper structure into each MX deal to ensure
            profitability, scalability and overall deal success. He does so with
            grace, tact and respect every single time. He is a true professional
            and comes with the proper information in hand.
          </p>
          <p>
            He doesn't engage in arguments, he just presents to facts and the
            data and ensures that the right people are always there to weigh in
            and get the job done so we can move forward. Massive respect for
            Andrew and what he brings to MX in this area.
          </p>
        </>
      ),
    },
    {
      name: "Michael Buffington",
      dept: "Engineering",
      award: "Exuberance for Life",
      img: "https://assets.mx.com/hub/images/founders-circle/2024/founder-michael.png",
      description: (
        <>
          <p>
            Michael very deliberately does only what he wants to do - and that
            means he always approaches work with a positive and enthusiastic
            attitude because he truly wants to do the things he does to make big
            impacts at MX. Working with Michael is a joy because he puts a
            positive spin on every situation without sugar coating the negative
            or being disingenuous. He reminds everyone around him to be grateful
            for the good things just by his natural demeanor and the example he
            sets of extracting joy from our work.
          </p>

          <p>
            If everyone at MX were like Michael Buffington, or if even just a
            handful of people were, it would help tremendously with employee
            retention and satisfaction. That's not to say that Michael doesn't
            speak up about problems when he sees them, but he does so in a
            rational, well reasoned and positive way that is more likely to win
            over hearts and minds.
          </p>

          <p>
            I myself have felt this effect from Michael after several candid
            conversations with him, in which he helped me gain valuable
            perspective on some of the challenges I have faced during my time at
            MX.
          </p>

          <p>
            In summary, Michael makes working at MX better, not only by bringing
            a more positive perspective, but by being relatable, friendly, and
            down to Earth about the good and the bad aspects of our day to day
            work experience. In this way, he embodies the MX value of exuberance
            for life better than anyone else I know at MX.
          </p>
        </>
      ),
    },
    {
      name: "Nadia Bahash",
      dept: "Product",
      award: "Cognizance",
      img: "https://assets.mx.com/hub/images/founders-circle/2024/founder-nadia.png",
      description: (
        <>
          <p>
            Nadia started at MX just over a year ago as the designer over
            customer analytics and client dashboard. She is a trusted partner to
            product and engineering. People often say, when describing Nadia,
            "we need more Nadias". She is empathetic and kind, she chooses her
            words wisely and with care, she thinks before she speaks, something
            you can physically see her doing in a way that makes you know that
            what she is about to say is important to her and she makes it be
            important to you. She is kind and is a peer mentor. She is bold and
            as a designer, is always, above all else, obsessed with the impact
            to users.
          </p>

          <p>
            I want to share a story here, not because I think it will help her
            win but because it demonstrates the kind of human she is...and I
            want everyone to know it! She isn't just good at her job, she is
            good at being an empathetic human.
          </p>

          <p>
            In recent days, Nadia witnessed a violent act. On her morning walk,
            she observed a person being brutally attacked. No one else was
            around when this occurred and they begged her for help. Nadia called
            911 and kept the person alive tending to their wounds until help
            arrived.
          </p>
          <p>
            I am so proud to know someone as kind and caring as Nadia and even
            more grateful that we get to have her building MX. People are right,
            the world needs more Nadias.
          </p>
        </>
      ),
    },
    {
      award: "Purposeful Contribution",
      name: "Tejaswini Kosunam",
      dept: "Engineering",
      img: "https://assets.mx.com/hub/images/founders-circle/2024/founder-tejaswini.png",
      description: (
        <>
          <p>
            Tejaswini knows how to make an impact. She constantly solves new
            challenges encountered with the GCP migration and responsibilities
            (mitigating production issues, increasing access to enable the
            team).
          </p>

          <p>
            She gets shit done, holds herself and team members accountable for
            commitments, and has an extraordinary bias towards execution. She is
            also knows how to forecast impact of problems and address the issues
            and projects that will most push the trajectory of MX in a positive
            direction.
          </p>

          <p>
            Tejaswini is one of the most powerful contributors I have ever
            known. She takes on huge projects and delivers every time. MX as a
            whole and our team would not be what it is without her.
          </p>

          <p>
            She has worked across teams and domains. She has taught, trained,
            lead, and built. I am in awe of her contribution.
          </p>
        </>
      ),
    },
    {
      award: "Foundation of Trust",
      name: "Karen Betten",
      dept: "Product and Technology",
      img: "https://assets.mx.com/hub/images/founders-circle/2024/founder-karen.png",
      description: (
        <>
          <p>
            Throughout my time here at MX I have seen Karen in a variety of
            roles and in every case she gives it all and make a difference. More
            recently Karen has been leading one of the of the most complex (and
            certainly the most financially significant with a deal value of
            $27M) Mobile implementations in company history. I have seen her
            step in from the very beginning (agreeing to move teams to take on a
            totally new role unfamiliar to her) to literally save the day as the
            customer facing Project Leader of the Dedicated Team and the
            implementation.
          </p>

          <p>
            She has seen countless escalations and has rallied support around
            her to make sure the client is getting what they need. Literal sweat
            and tears....and likely blood from beating her head on her desk
            figuratively to try and find solutions and keep a very difficult
            client happy. I am so impressed with Karen. I am so grateful for
            Karen as I have been close enough to the situation to know the
            details.
          </p>

          <p>
            Many many late nights continue, and I am sure she loses sleep trying
            to make ends meet. Karen is a natural leader and is deeply committed
            to excellence in her craft. In her previous roles I know clients and
            internal team mates have very much appreciated her and all she does
            to drive progress. Without hesitation I nominate Karen. Karen
            exhibits multiple values and with her work recently you could hit
            ALL of them but since I have to choose one, I KNOW that Hancock
            Whitney and her immediate team trust and appreciate Karen because
            they know exactly where she stands on every issue and every
            situation. She is reliable reliable reliable!
          </p>
        </>
      ),
    },
    {
      award: "Founder Mindset",
      name: "Cambree King",
      dept: "Revenue",
      img: "https://assets.mx.com/hub/images/founders-circle/2024/founder-cambree.png",
      description: (
        <>
          <p>
            Cambree is such a force for good at MX. She has taken on owning the
            RFP process and is actively improving the process and asking all of
            the right questions to get answers. She was put in this role not
            knowing exactly what to expect but has gone above and beyond to step
            up and take real ownership over RFPs. She is a delight to work with
            and fantastic cross functional partner. The results of her work will
            begin to bloom over time but her approach and mindset of a founder
            are setting her up for success.
          </p>
        </>
      ),
    },
    {
      award: "Iterative Innovation",
      name: "Kevin Watson",
      dept: "Engineering",
      img: "https://assets.mx.com/hub/images/founders-circle/2024/founder-kevin.png",
      description: (
        <>
          <p>
            This one was really cool to see Kevin take on, in addition to his
            mountain of other tasks that have been assigned to his team. His
            team recently took on the Bunyan project, which is client facing and
            has been plagued with lots of issues. Kevin has worked long hours to
            restructure the project, small pieces at a time. This iterative
            approach has been able to make the entire project more stable and
            reliable. This project was also able to be upgraded and moved onto
            the Kubernetes platform thanks to those iterative improvements.
          </p>

          <p>
            I want to stress that these improvements have been done at the same
            time as several other high priority projects, and Kevin is a
            rockstar for being able to deliver on all of it, as he continues to
            make innovative, iterative, changes to improve our products
            reliability.
          </p>
        </>
      ),
    },
  ];

  function toggleAccordion(num) {
    setActiveAccordion(num);
  }

  return (
    <>
      <PageWrapper>
        <InnerWrapper>
          <Hero>
            <h3>NOVEMBER 21, 2024</h3>
            <h1>FOUNDERS CIRCLE AWARDS</h1>
            <p>
              In honor of our MX founders, we are excited to introduce the
              Founders Circle Awards; an initiative to recognize MXers who
              embody our MX values.
            </p>
            {/* <BlueBtn
              href="https://www.wrike.com/workspace.htm?acc=379363&wr=2#/forms?formid=649494"
              target="_blank"
            >
              Submit A Nomination
            </BlueBtn> */}
          </Hero>
        </InnerWrapper>

        <Winners>
          <h2>2024 Founders Circle Award Winners</h2>
          <div className="wrap">
            {winnerList.map((person, index) => {
              return (
                <div
                  className={`winner ${
                    person.award === "Founders Award" ? "founder" : ""
                  }`}
                  key={index}
                >
                  <div className="profile">
                    <img src={person.img} />
                  </div>

                  <div className="text">
                    <p className="award-type">{person.award}</p>
                    <h3 className="name">{person.name}</h3>
                    <p className="dept">{person.dept}</p>
                    <div className="desc">{person.description}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </Winners>
        <img
          alt="group picture"
          id="group-pic"
          src="https://assets.mx.com/hub/images/founders-circle/2024/5B1A3363-1.jpg"
        />
      </PageWrapper>
    </>
  );
}

export default withTheme(FoundersCircle);

const PageWrapper = styled.section`
  background: ${({ theme }) => theme.backgroundColor};
  padding-bottom: 50px;
  * {
    margin: 0;
    padding: 0;
    font-family: Proxima-nova;
  }

  .sm-gray {
    color: ${({ theme }) => theme.textGray};
    font-weight: 600;
    letter-spacing: 1.1;
    font-size: 18px;
    margin: 0;
  }
  #group-pic {
    width: 100%;
  }
`;

const InnerWrapper = styled.div`
  background: url(${HeroBG});
  background-size: cover;
  background-repeat: no-repeat;
`;
const Hero = styled.div`
  max-width: 1435px;
  margin: 0 auto;
  text-align: left;
  // width: 100%;
  padding: 100px 50px;

  @media screen and (max-width: 900px) {
    padding: 100px 20px;
  }

  h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 1;
    color: #ffffff;
    margin-bottom: 50px;
    width: 100%;
  }

  h1 {
    font-weight: bold;
    font-size: 60px;
    line-height: 1;
    color: #ffffff;
    width: 100%;

    @media screen and (max-width: 900px) {
      font-size: 40px;
    }
  }
  p {
    margin: 50px 0;
    font-weight: normal;
    font-size: 18px;
    line-height: 1.2;
    color: #ffffff;
    width: 800px;
    max-width: 100%;
  }
`;

const Winners = styled.div`
  background: ${({ theme }) => theme.sectionColor};
  color: ${({ theme }) => theme.textColor};
  padding: 50px 20px;
  position: relative;

  h2 {
    font-size: 45px;
    margin: 0 auto;
    text-align: center;
    line-height: 1;
  }
  .wrap {
    width: 1000px;
    max-width: 100%;
    margin: 0 auto;
  }

  .winner {
    text-align: center;
    margin: 50px 0;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    @media only screen and (max-width: 1000px) {
      flex-direction: column;
    }

    .profile {
      width: 200px;
      max-width: 100%;
      margin-right: 40px;

      @media only screen and (max-width: 1000px) {
        margin-right: 0;
      }
      img {
        width: 100%;
        border-radius: 50%;
        border: 4px solid #1a73e8;
      }
    }

    .award-type {
      font-size: 25px;
      color: #1a73e8;
      font-weight: 700;
    }
    .text {
      text-align: left;
      flex: 1;

      @media only screen and (max-width: 1000px) {
        text-align: center;
        width: fit-content;
        max-width: 100%;
        margin: 0 auto;
      }

      .name {
        margin: 0;
        font-size: 18px;
        line-height: 1;
      }
      & > p {
        margin: 0;
      }
    }
    .desc {
      width: 600px;
      max-width: 100%;
      p {
        margin: 15px 0;
        font-size: 16px;
      }
    }

    &.founder {
      display: block;
      border-bottom: 2px solid ${({ theme }) => theme.divider};
      padding-bottom: 50px;
      .profile {
        margin: 10px auto;
        width: 300px;
      }
      .desc {
      }
      .text {
        text-align: center;
        width: fit-content;
        margin: 0 auto;
      }
    }
  }
`;
